import Image from "next/image";

export default function CustomerItem({imgSrc}) {
  return (
    <div className="mx-4 mt-5">
        <div className="p-6 bg-secondary text-white rounded-[5px]">
            <p className="text-base">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
            </p>
            <div className="flex flex-col mt-4">
                <span className="font-bold text-lg">Moana Mitchell</span>
                <span className="font-bold text-base">magna aliqua</span>
            </div>
        </div>
        <div className="relative w-28 h-28 rounded-full border-4 border-primary mt-8
        before:content-[''] before:absolute before:top-0 flex justify-center before:-translate-y-3 before:rotate-45
         before:bg-primary before:w-[20px] before:h-[20px]
        ">
            <Image className="rounded-full" src={imgSrc} alt="" layout="fill" objectFit="contain" />
        </div>
    </div>
  )
}
