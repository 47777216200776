import Image from "next/image";
import Button_primary from "./items/Button_primary";
import Title from "./Title";
import {FaHamburger,FaPizzaSlice} from "react-icons/fa";
import Slider from "react-slick";


const data = [
  { id: 1, im: '/images/hamburger1.jpg', baslik: 'Tasty Thurdays', indirim: '%20', icon: <FaHamburger />},
  { id: 2, im: '/images/pizza.jpg', baslik: 'Tasty Delicious', indirim: '%15', icon: <FaPizzaSlice />},
  { id: 3, im: '/images/hamburger1.jpg', baslik: 'Tasty Hamb1', indirim: '%10', icon: <FaHamburger />},
  { id: 4, im: '/images/hamburger2.png', baslik: 'Tasty Hamb2', indirim: '%30', icon: <FaHamburger />},
  { id: 5, im: '/images/hamburger3.png', baslik: 'Tasty Hamb3', indirim: '%35', icon: <FaHamburger />},
  { id: 6, im: '/images/hamburger4.png', baslik: 'Tasty Hamb4', indirim: '%40', icon: <FaHamburger />},
]

function liste ({id,im,baslik,indirim,icon}) {
  return (
    <div key={id} className="mx-5">
      <div className="bg-secondary flex-1 rounded-md py-5 px-[15px] !flex items-center">
        <div className="relative w-44 h-44 after:contents-[''] border-[5px] border-primary rounded-full overflow-hidden">
              <Image className="hover:scale-150 transition-all" src={im} alt="" layout="fill" objectFit="cover" />
        </div>
        <div className="text-white">
            <Title baslik={baslik} addClass="text-2xl font-dancing hover:scale-150 transition-all" />
            <div className="font-dancing  my-2 hover:scale-150 transition-all">
              <span className="text-[2.5rem]">{indirim}</span>
              <span> off</span>
            </div>
            <Button_primary label={"Order Now"}/>
        </div>
        <div className="text-white text-7xl hover:scale-150 transition-all">
            {icon}
        </div>
      </div>
    </div>
  )
}

export default function Campaigns() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true
  };

  return (
    <div className="container relative justify-between mx-auto py-20 flex-wrap">
        <Slider {...settings}>
        {
            data.map((id,im,baslik,indirim,icon) => (
              liste(id=id,im=im,baslik=baslik,indirim=indirim,icon=icon)
            )
            ) 
        }
        </Slider>
    </div>
  )
}
