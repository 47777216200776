import Slider from "react-slick";
import Image from "next/image";
import Title from "./Title";
import Button_primary from "./items/Button_primary"

export default function Carousel() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true
  };

  const data = [
      {   
          id: 1
        , baslik: 'Fast Food Restaurant 1'
        , prg: '1. Doloremque, itaque aperiam facilis rerum, commodi, temporibus sapiente ad mollitia laborum quam quisquam esse error unde. Tempora ex doloremque, labore, sunt repellat dolore, iste magni quos nihil ducimus libero ipsam.'
      },
      {   
          id: 2
        , baslik: 'Fast Food Restaurant 2'
        , prg: '2. Doloremque, itaque aperiam facilis rerum, commodi, temporibus sapiente ad mollitia laborum quam quisquam esse error unde. Tempora ex doloremque, labore, sunt repellat dolore, iste magni quos nihil ducimus libero ipsam.'
      },
      { 
          id: 3
        , baslik: 'Fast Food Restaurant 3'
        , prg: '3. Doloremque, itaque aperiam facilis rerum, commodi, temporibus sapiente ad mollitia laborum quam quisquam esse error unde. Tempora ex doloremque, labore, sunt repellat dolore, iste magni quos nihil ducimus libero ipsam.'
      },
      {   
          id: 4
        , baslik: 'Fast Food Restaurant 4'
        , prg: '4. Doloremque, itaque aperiam facilis rerum, commodi, temporibus sapiente ad mollitia laborum quam quisquam esse error unde. Tempora ex doloremque, labore, sunt repellat dolore, iste magni quos nihil ducimus libero ipsam.'
      },
      {   
        id: 5
      , baslik: 'Fast Food Restaurant 5'
      , prg: '5. Doloremque, itaque aperiam facilis rerum, commodi, temporibus sapiente ad mollitia laborum quam quisquam esse error unde. Tempora ex doloremque, labore, sunt repellat dolore, iste magni quos nihil ducimus libero ipsam.'
    }
  ];

function liste({id,baslik,prg}) {
  return (
    <div key={id}>
      <div className="mt-48 z-50 text-white flex flex-col justify-between items-start gap-y-10">
            <Title baslik={baslik} addClass="font-dancing text-6xl"/>
            <p className="text-sm sm:w-2/5 w-full">
              {prg}
            </p>
            <Button_primary label={"Order Now"}/>
      </div>
    </div>
  )
};

  return (
      <div className="h-screen w-full container mx-auto -mt-[88px]">
          <div className="absolute top-0 left-0 w-full h-full">
              <Image src="/images/hero-bg.jpg" alt="" layout="fill" objectFit="cover"/>
          </div>
            <Slider {...settings}>
            {
              data.map((id,baslik,prg) => (
                  liste(id=id,baslik=baslik,prg=prg)
                ))
            }
            </Slider>
        </div>  
      )
}
