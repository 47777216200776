import { useState } from "react";
import Title from "../Title";
// import MenuWrapperItem from "./MenuWrapperItem";
import Image from "next/image";
import { RiShoppingCart2Fill } from "react-icons/ri";
import Link from "next/link";



export default function MenuWrapper() {
  const [isSeciliBtn, setSeciliBtn] = useState(false);

  const data = [
    {id:1 , btnname: 'All'},
    {id:2 , btnname: 'Burger'},
    {id:3 , btnname: 'Pizza'},
    {id:4 , btnname: 'Pasta'},
    {id:5 , btnname: 'Fries'},
  ]

  function liste ({id,btnname}) {
    return (
      <button key={id} className={`py-2 px-6 rounded-3xl ${isSeciliBtn === true ? "bg-secondary text-white" : "text-black"}`} onClick={() => setSeciliBtn(true)}>{btnname}</button>
    )
  }

  // menuwrapper itemlerı için dizi oluşturuyoruz
  const mwidata = [
    {id: 1 , gorsel: '/images/sebzelipizza.png' , baslik: 'Delicious Pizza', fiyat: '$20'
    , yazi: 'Veniam debitis quaerat officiis quasi cupiditate quo, quisquam velit, magnam voluptatem repellendus sed eaque'
    },
    {id: 2 , gorsel: '/images/hamburger4.png' , baslik: 'Delicious Burger', fiyat: '$15'
    , yazi: 'Veniam debitis quaerat officiis quasi cupiditate quo, quisquam velit, magnam voluptatem repellendus sed eaque'
    },
    {id: 3 , gorsel: '/images/sebzelipizza.png' , baslik: 'Delicious Pizza', fiyat: '$17'
    , yazi: 'Veniam debitis quaerat officiis quasi cupiditate quo, quisquam velit, magnam voluptatem repellendus sed eaque'
    },
    {id: 4 , gorsel: '/images/kasarlimakarna.png' , baslik: 'Delicious Pasta', fiyat: '$18'
    , yazi: 'Veniam debitis quaerat officiis quasi cupiditate quo, quisquam velit, magnam voluptatem repellendus sed eaque'
    },
    {id: 5 , gorsel: '/images/kizartma.png' , baslik: 'French Fries', fiyat: '$10'
    , yazi: 'Veniam debitis quaerat officiis quasi cupiditate quo, quisquam velit, magnam voluptatem repellendus sed eaque'
    },
    {id: 6 , gorsel: '/images/yesilpizza.png' , baslik: 'Delicious Pizza', fiyat: '$15'
    , yazi: 'Veniam debitis quaerat officiis quasi cupiditate quo, quisquam velit, magnam voluptatem repellendus sed eaque'
    },
    {id: 7 , gorsel: '/images/hamburger2.png' , baslik: 'Tasty Burger', fiyat: '$12'
    , yazi: 'Veniam debitis quaerat officiis quasi cupiditate quo, quisquam velit, magnam voluptatem repellendus sed eaque'
    },
    {id: 8 , gorsel: '/images/hamburger3.png' , baslik: 'Tasty Burger', fiyat: '$14'
    , yazi: 'Veniam debitis quaerat officiis quasi cupiditate quo, quisquam velit, magnam voluptatem repellendus sed eaque'
    },
    {id: 9 , gorsel: '/images/kasarlimakarna.png' , baslik: 'Delicious Pasta', fiyat: '$10'
    , yazi: 'Veniam debitis quaerat officiis quasi cupiditate quo, quisquam velit, magnam voluptatem repellendus sed eaque'
    }, 
  ]

  // menuwrapper itemlerı için mwiliste funtionu oluşturuyoruz. Bu funtction döngü ile çekilecek
  function mwiliste ({id,gorsel,baslik,fiyat,yazi}) {
    return (
      <div key={id} className="bg-secondary rounded-3xl">
            <div className="w-full bg-[#f1f2f3] h-[180px] grid place-content-center rounded-bl-[46px] rounded-tl-2xl rounded-tr-2xl">
                <Link href="/product">
                  <div className="relative w-36 h-36 z-50 hover:scale-110 transition-all">
                      <Image src={gorsel} alt="" layout="fill"/>
                  </div>
                </Link>
            </div>
            <div className="text-white p-[25px]">
                <h4 className="font-semibold text-3xl font-dancing grid place-content-center">{baslik}</h4>
                <p className="text-[15px] mt-3">
                    {yazi}
                </p>
                <div className="flex justify-between p-[10px] items-center mt-2">
                    <div>{fiyat}</div>
                    <button className="bg-primary w-10 h-10 rounded-full grid place-content-center"><RiShoppingCart2Fill /></button>
                </div>
            </div>
        </div>    
    )
  }


  return (
    <div className="container mx-auto flex flex-col items-center mb-16 my-16">
        <Title baslik={"Our Menu"} addClass="font-dancing text-5xl" />
        {/* butonlar */}
        <div className="flex justify-center my-8 gap-5">
        {
          data.map((id,btnname) => (
              liste(id=id,btnname=btnname)
            ))
        }
        </div>
        {/* menuwrapper itemlerı */}
        <div className="mt-8 grid md:grid-cols-3 sm:grid-cols-3 grid-cols-1 gap-4 ">
        {
          mwidata.map((id,gorsel,baslik,fiyat,yazi) => (
            mwiliste(id=id,gorsel=gorsel,baslik=baslik,fiyat=fiyat,yazi=yazi)
            ))
        }
        </div>
        {/*view more butonu */}
        <div>
          <button className="bg-primary text-white rounded-[45px] mt-[45px] flex justify-center px-[55px] py-[10px]" >View more</button>
        </div>
    </div>
  )
}
