import { useFormik } from "formik";
import Input from "./form/input";
import Button_primary from "./items/Button_primary";
import { reservationSchema } from "./schema/reservationSchema";
import Title from "./Title";

export default function Reservation() {

  const onSubmit = async (values, actions) => {
    await new Promise((resolve) => setTimeout(resolve, 4000));
    actions.resetForm();
  }

  const {values, errors, touched, handleSubmit, handleChange, handleBlur} = useFormik({
    initialValues: {
      fullName: '',
      phoneNumber: '',
      yourMail: '',
      manyPersons: '',
      resevationDate: '',
    },
    onSubmit,
    validationSchema: reservationSchema
  });

  const inputs = [
    {id:1, type: 'text', name: 'fullName', label: 'Your Name'
    , value: values.fullName
    , errorMessage: errors.fullName
    , touched: touched.fullName
    },
    {id:2, type: 'tel', name: 'phoneNumber', label: 'Phone Number'
    , value: values.phoneNumber
    , errorMessage: errors.phoneNumber
    , touched: touched.phoneNumber
    },
    {id:3, type: 'text', name: 'yourMail', label: 'Your Mail'
    , value: values.yourMail
    , errorMessage: errors.yourMail
    , touched: touched.yourMail
    },
    {id:4, type: 'number', name: 'manyPersons', label: 'Haw Many Persons'
    , value: values.manyPersons
    , errorMessage: errors.manyPersons
    , touched: touched.manyPersons
    },
    {id:5, type: 'datetime-local', name: 'resevationDate', label: 'Date'
    , value: values.resevationDate
    , errorMessage: errors.resevationDate
    , touched: touched.resevationDate
    },
  ]


  return (
    <div className="container mx-auto py-12 place-content-center">
        <div>
            <Title baslik="Book A Table" addClass="text-[40px] font-dancing mb-10" />
        </div>
       <div className="flex justify-between items-center gap-x-16     ">
         <form className="lg:flex-1 w-full" onSubmit={handleSubmit} >
             <div className="flex flex-col gap-y-3">
                {
                    inputs.map((input) => (                             
                      <Input key={input.id} {...input} 
                      onChange={handleChange} 
                      onBlur={handleBlur}
                      />
                      ))
                }
             </div>
             <div className="mt-20">
                <Button_primary type="submit" label="Book Now"/>
             </div>
         </form>
         <div className="lg:flex-1 w-full">
            <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1492.6086664479212!2d38.74759224818012!3d40.12926187218509!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x407b2745eb082465%3A0xfc61603ecd992c4f!2sMarshall%20-%20Kale%20Nalburiye!5e0!3m2!1str!2str!4v1667486289434!5m2!1str!2str" 
                width="600" 
                height="450" 
                allowFullScreen="" 
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade">
            </iframe>
         </div>
       </div>
    </div>
  )
}
