
export default function Input(props) {

   const {type, label, errorMessage, touched, ...inputProps} = props;
  // {type = props,name,label,placeholder,value}
  return (
    <div className="w-full">
        <label className="relative block w-full">
            <input type={type}
            className={`h-14 w-full border outline-none px-4 peer 
            ${type !== "datetime-local" && "pt-2"}
            ${errorMessage ? "border-red-500" : "border-primary"}
            `
            }
            required 
            {...inputProps}
            />
            <span className="absolute top-0 left-4 text-sm flex h-full items-center peer-focus:h-7 peer-focus:text-xs  peer-valid:h-7 peer-valid:text-xs transition-all">{label}</span>
        </label>
        {touched && <span className="text-xs text-red-600">{errorMessage}</span>}
    </div>
  )
}
