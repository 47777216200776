import * as Yup from 'yup';

export const reservationSchema = Yup.object({
    fullName: Yup.string()
           .required("Full name is required")
           .min(3,"Full name must be at least 3 characters"),
    phoneNumber: Yup.string()
           .required("Phone number is required")
           .min(10, "Phone number must be at least 10 characters"),
    yourMail: Yup.string()
            .required("Email is required")
            .email("Invalid email address"),
    manyPersons: Yup.string()
            .required("persons is required"),
    resevationDate: Yup.string()
            .required("Date is required")
});