import Image from "next/image"
import Title from "./Title"

export default function AboutComp() {
  return (
    <div className="bg-secondary py-14">
        <div className="container mx-auto items-center gap-40 flex justify-center">
            <div className="flex justify-center">
              <div className="relative sm:w-[455px] sm:h-[600px] flex justify-center w-[300px] h-[450px]">
                  <Image src="/images/about-img.png" alt="" layout="fill"/>
              </div>
            </div>
            <div className="text-white">
                <Title addClass="text-[2.5rem] font-dancing" baslik="We Are Feane"/>
                <p className="mt-4">
                There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don`t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn`t anything embarrassing hidden in the middle of text. All
                </p>
                <button className="bg-primary py-[10px] px-[45px] rounded-[45px] mt-9">Read More</button>
            </div>
        </div>
    </div>
  )
}
