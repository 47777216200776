import AboutComp from '../components/AboutComp'
import Campaigns from '../components/Campaigns'
import Carousel from '../components/Carousel'
import Customers from '../components/customers/Customers'
import MenuWrapper from '../components/Product/MenuWrapper'
import Reservation from '../components/Reservation'

export default function Home() {

  return (
    <div>
      <Carousel />
      <Campaigns />
      <MenuWrapper />
      <AboutComp />
      <Reservation />
      <Customers />
    </div>
  )
}