import Title from "../Title";
import CustomerItem from "./CustomerItem";
import Slider from "react-slick";


export default function Customers() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
  };

  return (
    <div className="container mx-auto my-20">
        <Title baslik="What says our customers" addClass="font-dancing text-[40px] text-center" />
        <Slider {...settings}>
              <CustomerItem imgSrc="/images/client1.jpg" />
              <CustomerItem imgSrc="/images/client2.jpg" />
              <CustomerItem imgSrc="/images/client2.jpg" />
              <CustomerItem imgSrc="/images/client2.jpg" />
              <CustomerItem imgSrc="/images/client2.jpg" />
              <CustomerItem imgSrc="/images/client2.jpg" />

        </Slider>
    </div>
  )
}
